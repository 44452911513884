import React from "react"
import type { PageProps } from "gatsby"
import NewsNestedPostPage from "../../sections/OldDesign/news/Nested/NewsNestedPostPage"
import { WPConstants } from "../../sections/OldDesign/news/Constants"

const ProjectReports: React.FC<PageProps> = ({ location }) => {
    return (
        <NewsNestedPostPage
            pathname={location.pathname}
            title="Project Reports"
            categoryIds={[WPConstants.CATEGORIES.PROJECT_REPORTS]}
        />
    )
}

export default ProjectReports
